<template>
    <div class="row tms-datatable">
      <div class="col-12">
        <div class="row align-items-center mb-2">
          <div v-if="!hideSearch" class="col-4">
            <div class="form-group m-0" v-if="displaySearch">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="mdi mdi-magnify"></i>
                  </span>
                </div>
                <input
                  type="text"
                  v-model="filterText"
                  @keyup="doFilter"
                  class="form-control"
                  placeholder="Buscar"
                  aria-label="Buscar"
                >
                <span class="input-group-append" v-if="filterText">
                  <button class="btn btn-outline-default" type="button" @click.prevent="resetFilter">
                    <i class="mdi mdi-close"></i>
                  </button>
                </span>
              </div>
            </div>
            <div v-else>
              <a @click.prevent="displaySearch=true" href="#">
                <i class="mdi mdi-filter-variant"></i>
              </a>
            </div>
          </div>
          <div class="col-auto ml-auto">
            <column-visibility :fields.sync="fields" />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-2 my-2">
            <select v-model="per_page" name="per_page" id="per_page" class="form-control form-control-sm">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="table-responsive tms-datatable__body">

          <vuetable
            ref="vuetable"
            :append-params="otherParams"
            :api-url="api"
            :fields="fields"
            :http-options="httpOptions"
            :css="table"
            pagination-path=""
            :sort-order="sortOrder"
            :per-page="per_page"
            :no-data-template="'No hay datos disponibles'"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:load-error="handleLoadError"
            @vuetable:loaded="onLoaded"
            @vuetable:loading="onLoading"
          >
            <div slot="date_with_link" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                <span v-if="props.rowData.date">
                  {{ props.rowData.date | moment('DD-MM-YYYY hh:mm a') }}
                </span>
              </a>
            </div>

            <div slot="name" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                <span
                  v-if="props.rowData.name"
                  class="text-capitalize"
                >
                  {{ props.rowData.name | lowercase }}
                </span>
                <span v-else>
                  No Disponible
                </span>
              </a>
            </div>

            <div slot="event_type" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                <span v-if="props.rowData.type == 1" class="text-info font-weight-bold">
                  Cita
                </span>
                <span v-if="props.rowData.type == 2" class="text-info font-weight-bold">
                  Tarea
                </span>
                <span v-if="props.rowData.type == 3" class="text-info font-weight-bold">
                  Audiencia
                </span>
              </a>
            </div>

            <div slot="full_name_or_company" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                <span
                  v-if="props.rowData.type_id === 2"
                  class="text-capitalize"
                >
                  {{props.rowData.commercial_name | uppercase}}
                </span>
                  <span
                    v-else
                    class="text-capitalize"
                  >
                  {{props.rowData.first_name | uppercase}} {{props.rowData.last_name | uppercase}}
                </span>
              </a>
            </div>

            <div slot="full_name" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)" class="text-capitalize">
                {{ props.rowData.first_name | uppercase }} {{ props.rowData.last_name | uppercase }}
              </a>
            </div>

            <div slot="plain_name" slot-scope="props">
              <span class="text-capitalize">
                {{props.rowData.name | lowercase}}
              </span>
            </div>

            <div slot="created_by" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                {{props.rowData.creator.first_name}} {{props.rowData.creator.last_name}}
              </a>
            </div>

            <div slot="expense" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                {{ props.rowData.created_at | moment('DD-MM-YYYY') }}
              </a>
            </div>

            <div slot="payment" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                {{ props.rowData.created_at | moment('DD-MM-YYYY') }}
              </a>
            </div>

            <div slot="client" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-client-edit', props.rowData.client)">
                <span
                  v-if="props.rowData.client.type_id === 2"
                  class="text-capitalize"
                >
                  {{props.rowData.client.commercial_name | lowercase | truncate }}
                </span>
                <span
                  v-else
                  class="text-capitalize"
                >
                  {{ props.rowData.client.first_name + ' ' + props.rowData.client.last_name | lowercase | truncate }}
                </span>
                <div class="my-1">
                  <small class="text-capitalize">
                    <strong>Abogado:</strong>
                    {{ props.rowData.lawyer ?
                      props.rowData.lawyer.first_name ? props.rowData.lawyer.first_name : ''
                        + ' '
                        + props.rowData.lawyer.last_name ? props.rowData.lawyer.last_name : '' : 'No asignado' | lowercase | truncate}}
                  </small>
                </div>
              </a>
            </div>

            <div slot="agenda_client" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-client-edit', props.rowData.client)">
                <span
                  v-if="props.rowData.client.type_id === 2"
                  class="text-capitalize"
                >
                  {{props.rowData.client.commercial_name | lowercase | truncate }}
                </span>
                <span
                  v-else
                  class="text-capitalize"
                >
                  {{ props.rowData.client.first_name + ' ' + props.rowData.client.last_name | lowercase | truncate }}
                </span>
              </a>
            </div>

            <div slot="agenda_event_type" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                <span class="font-weight-bold" v-if="props.rowData.type === 1">
                  <i class="mdi mdi-update"></i> Cita
                </span>
                <span class="font-weight-bold" v-else-if="props.rowData.type === 2">
                  <i class="mdi mdi-checkbox-marked-outline"></i> Tarea
                </span>
                <span class="font-weight-bold" v-else-if="props.rowData.type === 3">
                  <i class="mdi mdi-account-group"></i> Audiencia
                </span>
              </a>
            </div>

            <div slot="agenda_action" slot-scope="props">
              <router-link v-if="props.rowData.type === 3" :to="{name : 'agenda_report', params: { id: props.rowData.id }}" target="_blank">
                Ver Reporte
              </router-link>
            </div>

            <div slot="provider" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                  {{ props.rowData.provider.name }}
              </a>
            </div>

            <div slot="record" slot-scope="props">
              <router-link
                v-if="props.rowData.record"
                :to="{name : 'record_details', params: { id: props.rowData.record.id }}"
                class="text-capitalize"
              >
                {{ props.rowData.record.name | lowercase }}
              </router-link>
              <span v-else>No Seleccionado</span>
            </div>

            <div slot="client_records_count" slot-scope="props">
              <router-link :to="{name : 'client_records', params: { client_request: props.rowData.id }}" class="badge badge-success">
                {{ props.rowData.record.length }}
              </router-link>
            </div>

            <div slot="client_agenda_count" slot-scope="props">
              <router-link :to="{name : 'client_agenda', params: { client_request: props.rowData.id }}" class="badge badge-info">
                {{ props.rowData.agenda.length }}
              </router-link>
            </div>

            <div slot="record_client" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-client-edit', props.rowData.record.client)">
                <span v-if="props.rowData.record.client.type_id === 2">
                  {{props.rowData.record.client.commercial_name | truncate }}
                </span>
                <span v-else>
                  {{ props.rowData.record.client.first_name + ' ' + props.rowData.record.client.last_name | truncate }}
                </span>
                <div class="my-1">
                  <small><strong>Abogado:</strong> {{ props.rowData.record.lawyer ? props.rowData.record.lawyer.first_name + ' ' + props.rowData.record.lawyer.last_name : 'No asignado' | truncate }}</small>
                </div>
              </a>
            </div>

            <div slot="against_to" slot-scope="props">
              <div class="text-capitalize">
                {{ props.rowData.against_to | lowercase }}
              </div>
              <div class="my-1">
                <small v-if="props.rowData.defense_lawyer" class="text-capitalize">
                  <strong>Abogado:</strong>
                  {{ props.rowData.defense_lawyer | lowercase }}
                </small>
              </div>
            </div>

            <div slot="record_name" slot-scope="props">
              <router-link :to="{name : 'record_details', params: { id: props.rowData.id }}">
                <div class="text-capitalize">
                  {{props.rowData.name | lowercase}}
                </div>
                <span class="my-1">
                  <small>
                    {{props.rowData.number}}
                  </small>
                </span>
              </router-link>
            </div>

            <div class="text-center" slot="record_label" slot-scope="props">
              <router-link
                v-if="props.rowData"
                :to="{name : 'record_print_label', params: { id: props.rowData.id, record: props.rowData }}"
                class="text-capitalize"
                target="_blank"
              >
                <svg style="width: 25px;" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path></svg>
              </router-link>
            </div>

            <div slot="total" slot-scope="props">
              {{ (props.rowData.total ? props.rowData.total : '0.00') | formatMoney }}
            </div>

            <div slot="invoice_number" slot-scope="props">
              <a href="#" @click.prevent="onEvent('table-item-edit', props.rowData)">
                {{ 'Fact #. ' + props.rowData.id }}
              </a>
            </div>

            <div slot="invoice_payments" slot-scope="props">
              {{_.sumBy(props.rowData.payments, function (o) { if (o.amount > 0) { return parseFloat(o.amount) } } ) | formatMoney }}
            </div>

            <div slot="invoice_record" slot-scope="props">
              <router-link :to="{name : 'record_details', params: { id: props.rowData.invoice.record.id }}">
                {{ props.rowData.invoice.record.name.charAt(0).toUpperCase() + props.rowData.invoice.record.name.slice(1) }}
              </router-link>
            </div>

            <div slot="payment_receipt_button" slot-scope="props">
              <router-link :to="{name : 'payment_receipt', params: { id: props.rowData.id }}" target="_blank">
                Ver Recibo
              </router-link>
            </div>

            <div slot="expense_receipt_button" slot-scope="props">
              <router-link :to="{name : 'expense_receipt', params: { id: props.rowData.id }}" target="_blank">
                Ver Comprobante
              </router-link>
            </div>
            <div slot="bill_receipt_button" slot-scope="props">
              <router-link :to="{name : 'bill_receipt', params: { id: props.rowData.id }}" v-if="props.rowData.status === 1" target="_blank">
                Ver Comprobante
              </router-link>
              <button v-if="props.rowData.status !== 1"
                      class="btn btn-sm btn-gradient-success mr-1"
                      @click.prevent="onEvent('table-item-bill-set-payed', props.rowData)">
                <i class="mdi mdi-check-circle"></i> Pagar
              </button>
            </div>

            <template slot="invoice_actions" slot-scope="props">
              <a v-if="!props.rowData.payed" href="#" class="btn btn-sm btn-gradient-success mr-1" @click.prevent="onEvent('table-item-invoice-set-payed', props.rowData)"><i class="mdi mdi-check-circle"></i> Pagar</a>
              <router-link :to="{name : 'invoice_receipt', params: { id: props.rowData.id }}" class="btn btn-sm btn-outline-info" target="_blank">
                Ver
              </router-link>
            </template>

            <div slot="amount" slot-scope="props">
              {{ (props.rowData.amount ? props.rowData.amount : '0.00') | formatMoney }}
            </div>

            <template slot="actions" slot-scope="props">
              <a href="#" v-if="!hideDelete && props.rowData.deleted_at == null" class="btn btn-sm btn-danger" @click.prevent="onEvent('table-item-delete', props.rowData)">
                <span v-if="customDeleteBtnText">{{ customDeleteBtnText }}</span>
                <span v-else>Desactivar</span>
              </a>
              <a href="#" v-if="!hideRestore && props.rowData.deleted_at != null" class="btn btn-sm btn-success" @click.prevent="onEvent('table-item-restore', props.rowData)">
                <span v-if="customRestoreBtnText">{{ customRestoreBtnText }}</span>
                <span v-else>Activar</span>
              </a>
            </template>

            <template slot="task_action" slot-scope="props">
              <div class="dropdown" v-if="props.rowData.status !== 1 && props.rowData.status !== 3 && props.rowData.status !== 2">
                <button class="btn btn-default btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Acciones
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a href="#" class="dropdown-item" @click.prevent="onEvent('table-item-task-complete', props.rowData)"><i class="mdi mdi-check-circle"></i> Completar</a>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item" @click.prevent="onEvent('table-item-task-reschedule', props.rowData)"><i class="mdi mdi-calendar-clock"></i> Re-agendar</a>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item" @click.prevent="onEvent('table-item-task-cancel', props.rowData)"><i class="mdi mdi-block-helper"></i> Cancelar</a>
                </div>
              </div>
            </template>

            <template slot="action_attachment" slot-scope="props">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-danger btn-sm" @click.prevent="onEvent('table-item-attachment-delete', props.rowData)"><i class="mdi mdi-close"></i></button>
                <a type="button" class="btn btn-info btn-sm" target="_blank" :href="httpOptions.attachmentBaseURL + props.rowData.url"><i class="mdi mdi-cloud-download"></i></a>
              </div>
            </template>

            <template slot="action_attachment_details" slot-scope="props">
              <span v-if="props.rowData.category === 'Parte Contraria'">
                <button type="button" class="btn btn-outline-info btn-sm" @click.prevent="onEvent('table-attachment-details-show', props.rowData)">
                  Detalles
                </button>
              </span>
            </template>

          </vuetable>

          <div
            v-if="$wait.waiting(spinner)"
            class="tms-datatable__spinner w-100 text-center"
          >
            <b-spinner label="Loading..." />
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <vuetable-pagination-info
              ref="paginationInfo"
              :info-template="'Mostrando entradas del {from} al {to} de {total}'"
              :no-data-template="''"
            />
          </div>
          <vuetable-pagination
            ref="pagination"
            :css="pagination"
            @vuetable-pagination:change-page="onPaginationChangePage"
          />
        </div>
      </div>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import columnVisibility from 'vuetable2-column-visibility'
import { mapMutations } from 'vuex'

export default {
  props: ['label', 'api', 'fields', 'otherParams', 'hideDelete', 'hideRestore', 'hideSearch', 'customDeleteBtnText', 'customRestoreBtnText'],
  name: 'TableLayout',
  data () {
    return {
      filterText: '',
      per_page: 10,
      displaySearch: true,
      httpOptions: {
        baseURL: process.env.VUE_APP_BASE_URL,
        attachmentBaseURL: process.env.VUE_APP_ATTACHMENT_URL,
        withCredentials: true
      },
      table: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-striped table-hover',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-chevron-up',
        descendingIcon: 'fa fa-chevron-down',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'fa fa-sort',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon (classes, options) {
          return `<i class="${classes.join(' ')}"></span>`
        }
      },
      pagination: {
        wrapperClass: 'pagination col-auto',
        activeClass: 'active',
        disabledClass: 'disabled',
        pageClass: 'btn-nav page-link',
        linkClass: 'page-link',
        paginationClass: 'pagination',
        paginationInfoClass: 'float-left',
        dropdownClass: 'form-control',
        icons: {
          first: 'mdi mdi-chevron-double-left',
          prev: 'mdi mdi-chevron-left',
          next: 'mdi mdi-chevron-right',
          last: 'mdi mdi-chevron-double-right'
        }
      },
      spinner: 'spinner',
      sortOrder: [
        {
          field: 'id',
          direction: 'asc'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('Authentication', ['RESET']),
    handleLoadError (error) {
      if (error.response.status === 401) {
        this.RESET()
        this.$nextTick(() => this.$router.push({ name: 'login' }))
      }
      this.$swal({
        title: 'Oops!',
        text: error.response.data.message,
        type: 'error',
        confirmButtonText: 'Ok'
      }).then(() => {
        this.$nextTick(() => this.$router.push({ name: 'home' }))
      })
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onPaginationChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onTableRefresh () {
      this.$refs.vuetable.refresh()
    },
    onFilterSet (value) {
      this.otherParams = {
        filter: value
      }
      if (typeof value === 'object' && value !== null) {
        this.otherParams = value
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset () {
      this.otherParams = {
        filter: ''
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onEvent (action, data) {
      this.$events.fire(action, data, this.label)
    },
    onColumnVisibilityChanged () {
      this.$refs.vuetable.normalizeFields()
    },
    doFilter () {
      this.onFilterSet(this.filterText)
    },
    resetFilter () {
      this.filterText = null
      this.$nextTick(() => this.$events.fire('filter-reset'))
    },
    onLoading () {
      this.$wait.start(this.spinner)
    },
    onLoaded () {
      this.$wait.end(this.spinner)
    }
  },
  mounted () {
    this.$events.$on('refresh-table', () => this.onTableRefresh())
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
    this.$events.$on('column-visibility:changed', e => this.onColumnVisibilityChanged(e))
  },
  beforeDestroy () {
    this.$events.off('refresh-table')
    this.$events.off('filter-set')
    this.$events.off('filter-reset')
  },
  components: {
    Vuetable,
    VuetablePagination,
    columnVisibility,
    VuetablePaginationInfo
  }
}
</script>
